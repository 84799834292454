import React from "react";
import { TabList, TabListProps } from "./TabList";

const Component: React.FC<{}> = (props: {}) => {
  const tabs: Array<TabListProps> = [
    { href: "/document", label: "一覧" },
    { href: "/document/juto06", label: "銃砲所持許可申請書" },
    { href: "/document/juto09", label: "猟銃等所持許可更新申請書" },
    { href: "/document/juto13", label: "同居親族書" },
    { href: "/document/juto19", label: "猟銃等講習受講申込書" },
    { href: "/document/juto25", label: "技能講習受講申込書" },
    { href: "/document/juto74", label: "使用実績報告書" },
    { href: "/document/jutot01", label: "経歴書" },
    { href: "/document/kayaku02", label: "猟銃用火薬類等譲受許可申請書" }
  ];

  return <TabList tabList={tabs} />;
};

export const DocumentMenu = Component;
