import React from "react";
import { TabList, TabListProps } from "./TabList";

const Component: React.FC<{}> = (props: {}) => {
  const tabs: Array<TabListProps> = [
    { href: "/certificate/course-certificate", label: "講習修了証明書" },
    { href: "/certificate/training-certificate", label: "技能講習修了証明書" },
    { href: "/certificate/hunting-license", label: "狩猟免許" }
  ];

  return <TabList tabList={tabs} />;
};

export const CertificateMenu = Component;
