import React from "react";
import { TabList, TabListProps } from "./TabList";

const Component: React.FC<{}> = (props: {}) => {
  const tabs: Array<TabListProps> = [
    { href: "/license/gun", label: "銃砲" },
    { href: "/license/owner", label: "所持許可証" },
    { href: "/license/relatives", label: "同居親族" }
  ];

  return <TabList tabList={tabs} />;
};

export const LicenseMenu = Component;
