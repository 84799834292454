import React from "react";
import { TabList, TabListProps } from "./TabList";

const Component: React.FC<{}> = (props: {}) => {
  const tabs: Array<TabListProps> = [
    { href: "/ammo-event", label: "帳簿" },
    { href: "/ammo-event/ammunition", label: "火薬類マスター" },
    { href: "/ammo-event/event-name", label: "帳簿摘要マスター" },
    { href: "/ammo-event/place", label: "場所・相手方マスター" }
  ];

  return <TabList tabList={tabs} />;
};

export const AmmoEventMenu = Component;
