import React from "react";
import styled from "styled-components";

import MuiAppBar from "@material-ui/core/AppBar";
import MuiTab from "@material-ui/core/Tab";
import MuiTabs from "@material-ui/core/Tabs";

const AppBar = styled(MuiAppBar)`
  &.MuiAppBar-root {
    background-color: #fff;
    color: #007bff;
    z-index: 0;
  }
`;

const Tabs = styled(MuiTabs)`
  .MuiTabs-indicator {
    background: #ffa726;
  }
`;

export interface TabListProps {
  href: string;
  label: string;
}

type Props = {
  tabList: Array<TabListProps>;
};

const Component: React.FC<Props> = (props: Props) => {
  const pathName: string = window.location.pathname;

  var currntLocation: string = pathName.substr(-1) === "/" ? pathName.substr(0, pathName.length - 1) : pathName;

  const value: string = "/" + currntLocation.split("/").slice(1, 3).join("/");

  const tabs = props.tabList.map((tab: TabListProps, key: number) => {
    return <MuiTab key={key} value={tab.href} component="a" label={tab.label} href={`${tab.href}`} />;
  });

  return (
    <AppBar position="static">
      <Tabs value={value} variant="scrollable" scrollButtons="desktop">
        {tabs}
      </Tabs>
    </AppBar>
  );
};

export const TabList = Component;
