import React from "react";
import { TabList, TabListProps } from "./TabList";

const Component: React.FC<{}> = (props: {}) => {
  const tabs: Array<TabListProps> = [{ href: "/contact", label: "お問い合わせ" }];

  return <TabList tabList={tabs} />;
};

export const ContactMenu = Component;
