import * as React from "react";
import { AppBar, Tab, Tabs } from "@material-ui/core";

type FieldProps = {
  index: number;
  field: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
  placeholder: string;
};

const Field: React.FC<FieldProps> = (props: FieldProps) => {
  const s = window.location.href;
  return (
    <input
      data-index={props.index}
      data-field={props.field}
      onChange={props.onChange}
      name={`schedule[${props.index + 1}][${props.field}]`}
      value={props.value}
      placeholder={props.placeholder}
      className="form-control border-0"
      type="text"
    />
  );
};

type Plan = {
  date: string;
  event: string;
  place: string;
  remarks: string;
};

type Props = { className?: string };
type State = { schedule: Array<Plan> };

class Component extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { schedule: [] };
  }

  addPlan = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    e.preventDefault();
    const plan = { date: "", event: "", place: "", remarks: "" };
    this.setState({ schedule: this.state.schedule.concat(plan) });
  };

  removePlan = (index: number, e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    e.preventDefault();
    const plans: Array<Plan> = [...this.state.schedule];
    plans.splice(index, 1);
    console.log(index, plans);
    this.setState({ schedule: plans });
  };

  handleChange = (index: number, field: keyof Plan, e: React.ChangeEvent<HTMLInputElement>): void => {
    const schedule: Array<Plan> = this.state.schedule.slice();
    schedule[index][field] = e.currentTarget.value;
    this.setState({ schedule: schedule });
  };

  public render(): React.ReactNode {
    const planComponents: React.ReactNode = this.state.schedule.map((plan: Plan, index: number) => {
      return (
        <tr>
          <td>
            <div className="btn-group  btn-group-sm">
              {index === this.state.schedule.length - 1 ? (
                <>
                  <button className="btn btn-outline-primary btn-sm" onClick={e => this.removePlan(index, e)}>
                    <i className="fas fa-minus"></i>
                  </button>
                  <button className="btn btn-outline-primary btn-sm" onClick={this.addPlan}>
                    <i className="fas fa-plus"></i>
                  </button>
                </>
              ) : (
                <>
                  <button className="btn btn-outline-primary btn-sm" onClick={e => this.removePlan(index, e)}>
                    <i className="fas fa-minus"></i>
                  </button>
                  <button className="btn btn-outline-primary btn-sm disabled" disabled>
                    <i className="fas fa-plus"></i>
                  </button>
                </>
              )}
            </div>
          </td>
          <td>
            <Field index={index} field="date" onChange={e => this.handleChange(index, "date", e)} value={plan.date} placeholder="" />
          </td>
          <td>
            <Field index={index} field="event" onChange={e => this.handleChange(index, "event", e)} value={plan.event} placeholder="" />
          </td>
          <td>
            <Field index={index} field="place" onChange={e => this.handleChange(index, "place", e)} value={plan.place} placeholder="" />
          </td>
          <td>
            <Field
              index={index}
              field="remarks"
              onChange={e => this.handleChange(index, "remarks", e)}
              value={plan.remarks}
              placeholder=""
            />
          </td>
        </tr>
      );
    });

    return (
      <React.Fragment>
        <table className="v-document-get-table w-100">
          <thead>
            <tr>
              <th colSpan={5} className="text-center font-weight-normal">
                許可申請に係る種類の火薬類の消費（購入）計画
              </th>
            </tr>
            <tr>
              <th className="text-center font-weight-normal" colSpan={2}>
                予定時期
              </th>
              <th className="text-center font-weight-normal">予定数量</th>
              <th className="text-center font-weight-normal">予定場所</th>
              <th className="text-center font-weight-normal">備考</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div className="btn-group  btn-group-sm">
                  <button className="btn btn-outline-primary btn-sm disabled" disabled>
                    <i className="fas fa-minus"></i>
                  </button>
                  {this.state.schedule.length === 0 ? (
                    <button onClick={this.addPlan} className="btn btn-outline-primary btn-sm">
                      <i className="fas fa-plus"></i>
                    </button>
                  ) : null}
                </div>
              </td>
              <td>
                <input name="schedule[0][date]" placeholder="令和2年2月1日" className="form-control border-0" type="text" />
              </td>
              <td>
                <input name="schedule[0][event]" placeholder="実包 12番 250個 購入" className="form-control border-0" type="text" />
              </td>
              <td>
                <input name="schedule[0][place]" placeholder="〇〇銃砲店" className="form-control border-0" type="text" />
              </td>
              <td>
                <input name="schedule[0][remarks]" placeholder="" className="form-control border-0" type="text" />
              </td>
            </tr>
            {planComponents}
          </tbody>
        </table>
      </React.Fragment>
    );
  }
}

export default Component;
