import "../sass/app.scss";

import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom";
import { ThemeProvider, StylesProvider } from "@material-ui/core/styles";

import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";

import { defaultTheme } from "./gunmng/Adapter/Delivery/MaterialUI/Theme";

import { AmmoEventMenu } from "./gunmng/Adapter/Delivery/React/Component/AmmoEventMenu";
import { DocumentMenu } from "./gunmng/Adapter/Delivery/React/Component/DocumentMenu";
import { LicenseMenu } from "./gunmng/Adapter/Delivery/React/Component/LicenseMenu";
import { CertificateMenu } from "./gunmng/Adapter/Delivery/React/Component/CertificateMenu";
import { ContactMenu } from "./gunmng/Adapter/Delivery/React/Component/ContactMenu";

import DocumentKayaku02Schedule from "./scene/document/kayaku02/v1/schedule";
// import RelativesPage from "./gunmng/Adapter/Delivery/React/Page/Relatives/Page";
// import DocumentsJuto13Page from "./gunmng/Adapter/Delivery/React/Page/Documents/Juto13/Page";

const RelativesPage = lazy(() => import("./gunmng/Adapter/Delivery/React/Page/Relatives/Page"));
const LoginPage = lazy(() => import("./gunmng/Adapter/Delivery/React/Page/Login/Page"));
const DocumentsJuto13Page = lazy(() => import("./gunmng/Adapter/Delivery/React/Page/Documents/Juto13/Page"));

if (document.getElementById("scene-document-kayaku02-schedule") !== null) {
  ReactDOM.render(
    <StylesProvider injectFirst>
      <DocumentKayaku02Schedule />
    </StylesProvider>,
    document.getElementById("scene-document-kayaku02-schedule")
  );
}

if (document.getElementById("component-ammoEvent-menu") !== null) {
  ReactDOM.render(
    <StylesProvider injectFirst>
      <AmmoEventMenu />
    </StylesProvider>,
    document.getElementById("component-ammoEvent-menu")
  );
}

if (document.getElementById("component-document-menu") !== null) {
  ReactDOM.render(
    <StylesProvider injectFirst>
      <DocumentMenu />
    </StylesProvider>,
    document.getElementById("component-document-menu")
  );
}

if (document.getElementById("component-license-menu") !== null) {
  ReactDOM.render(
    <StylesProvider injectFirst>
      <LicenseMenu />
    </StylesProvider>,
    document.getElementById("component-license-menu")
  );
}

if (document.getElementById("component-certificate-menu") !== null) {
  ReactDOM.render(
    <StylesProvider injectFirst>
      <CertificateMenu />
    </StylesProvider>,
    document.getElementById("component-certificate-menu")
  );
}

if (document.getElementById("component-contact-menu") !== null) {
  ReactDOM.render(
    <StylesProvider injectFirst>
      <ContactMenu />
    </StylesProvider>,
    document.getElementById("component-contact-menu")
  );
}

// 同居親族書
if (document.getElementById("page-documents-juto13-index") !== null) {
  ReactDOM.render(
    <Suspense
      fallback={
        <Backdrop open={true}>
          <CircularProgress />
        </Backdrop>
      }
    >
      <StylesProvider injectFirst>
        <ThemeProvider theme={defaultTheme}>
          <DocumentsJuto13Page />
        </ThemeProvider>
      </StylesProvider>
    </Suspense>,
    document.getElementById("page-documents-juto13-index")
  );
}

// 同居親族
if (document.getElementById("page-license-relatives-index") !== null) {
  ReactDOM.render(
    <Suspense
      fallback={
        <Backdrop open={true}>
          <CircularProgress />
        </Backdrop>
      }
    >
      <StylesProvider injectFirst>
        <ThemeProvider theme={defaultTheme}>
          <RelativesPage />
        </ThemeProvider>
      </StylesProvider>
    </Suspense>,
    document.getElementById("page-license-relatives-index")
  );
}

// ログイン
if (document.getElementById("jp_nilay_gunman_login") !== null) {
  ReactDOM.render(
    <Suspense
      fallback={
        <button type="submit" className="btn btn-outline-primary w-100 mt-4 v_login_signIn" disabled={true}>
          <span className="spinner-border spinner-border-sm me-3"></span>
          ログイン
        </button>
      }
    >
      <StylesProvider injectFirst>
        <ThemeProvider theme={defaultTheme}>
          <LoginPage />
        </ThemeProvider>
      </StylesProvider>
    </Suspense>,
    document.getElementById("jp_nilay_gunman_login")
  );
}
