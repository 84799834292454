import { createMuiTheme } from "@material-ui/core/styles";

export const defaultTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#37474f"
    },
    secondary: {
      main: "#ffa726"
    }
  }
});
